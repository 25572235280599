<template>
  <div class="wrap">
    <div class="wrap-header">
      <el-breadcrumb>
        <el-breadcrumb-item class="font-breadcrumb"><a>Cargo Tracking </a></el-breadcrumb-item>
        <el-breadcrumb-item> </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="wrap-content">
      <el-form ref="queryform" :model="queryform" label-width="140px" class="queryform">
        <el-form-item label="AWB#">
          <el-input v-model="queryform.awb" @input="awbChange" ref="awbInput" placeholder="Up to 5 numbers.Separate by a comma (,)" maxlength="64"></el-input>
          <div class="awb-error-potision" v-if="errorAwbList.length>0">
            <span v-for="(item, index) in awbList" :key="index" :class="errorAwbList.indexOf(item) === -1?'input-transparent':'input-error'" :style="index==0?'margin-left:15px;':''"><i v-if="index!==0" class="awb-error-text">,</i>{{item}}</span>
          </div>
        </el-form-item>
        <el-form-item label="">
          <div class="Advanced-title" @click="openAdvancedCriteria">
            <span :class="showAdvancedCriteria ? 'span-bottom' : ''">Advanced criteria</span>
            <svg-icon :icon-class="showAdvancedCriteria?'down':'up'" style="width:16px;height:16px;border-radius: 12px;margin-left:20px;vertical-align: -3px;"/>
          </div>
        </el-form-item>
        <el-form-item label="MAWB#" v-if="showAdvancedCriteria">
          <el-input v-model="queryform.mawb" @input="mawbChange" ref="mawbInput"></el-input>
          <div class="mawb-error-optision">
            <span v-for="(item, index) in errorMawbList" :key="index" class="input-error"><i v-if="index!==0">,</i>{{item}}</span>
          </div>
        </el-form-item>
        <el-form-item label="Flight Date" v-if="showAdvancedCriteria">
          <el-date-picker
            v-model="queryform.flightDate"
            type="daterange"
            range-separator="-"
            start-placeholder="From date"
            end-placeholder="To date"
            :default-time="['00:00:00', '23:59:59']"
            format="yyyy - MM - dd"
            value-format="yyyy-MM-dd">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="Origin" v-if="showAdvancedCriteria">
          <el-input v-model="queryform.origin"></el-input>
        </el-form-item>
        <el-form-item label="Dest" v-if="showAdvancedCriteria">
          <el-input v-model="queryform.dest"></el-input>
        </el-form-item>
        <el-form-item label="Shipper" v-if="showAdvancedCriteria">
          <el-input v-model="queryform.shipper"></el-input>
        </el-form-item>
        <el-form-item label="Consignee" v-if="showAdvancedCriteria">
          <el-input v-model="queryform.consignee"></el-input>
        </el-form-item>
        <el-form-item label="Out Office Code" v-if="showAdvancedCriteria">
          <el-input v-model="queryform.outOfficeCode"></el-input>
        </el-form-item>
        <el-form-item label="In Office Code" v-if="showAdvancedCriteria">
          <el-input v-model="queryform.inOfficeCode"></el-input>
        </el-form-item>
        <el-form-item class="query-btn">
          <div class="query-btn-box">
            <el-button type="primary" size="small" @click="onSubmit">Track</el-button>
            <el-button size="small" @click="reset('queryform')">Reset</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { validationAwb } from '@/api/cargo-tracking/cargo-tracking-api.js'
import { getOfficeCode } from '@/utils/auth'

export default {
  name: 'cargo-tracking-query',
  data () {
    return {
      queryform: {
        awb: '',
        mawb: '',
        flightDate: '',
        origin: '',
        dest: '',
        shipper: '',
        consignee: '',
        inOfficeCode: '',
        outOfficeCode: ''
      },
      showAdvancedCriteria: false,
      awbList: [],
      errorAwbList: [],
      errorMawbList: []
    }
  },
  methods: {
    openAdvancedCriteria () {
      this.showAdvancedCriteria = !this.showAdvancedCriteria
    },
    onSubmit () {
      let obj = {
        data: {},
        loading: true
      }
      let emptyList = []
      for (let i in this.queryform) {
        if (this.queryform[i] !== '' && i !== 'flightDate') {
          this.$set(obj.data, i, this.queryform[i].toUpperCase())
        } else if (this.queryform[i] !== '' && i === 'flightDate') {
          this.$set(obj.data, 'fromeDate', this.queryform[i][0] + ' 00:00:00')
          this.$set(obj.data, 'toDate', this.queryform[i][1] + ' 23:59:59')
        } else if (this.queryform[i] === '') emptyList.push(true)
      }
      if (emptyList.length === 9) {
        this.$set(obj.data, 'fromeDate', this.get3monthsDay() + ' 00:00:00')
        this.$set(obj.data, 'toDate', this.getMonthsDayNow() + ' 23:59:59')
      }
      let objValidation = {
        data: {
          officeCode: getOfficeCode(),
          awb: this.queryform.awb.toUpperCase()
        },
        loading: true
      }
      if (this.queryform.awb.toUpperCase() === '') {
        let params = {}
        for (let item in obj.data) {
          this.$set(params, item, obj.data[item])
        }
        this.$router.push({ path: '/cargo-tracking/tracking-result', query: params })
      } else {
        validationAwb(objValidation).then(res => {
          if (res.data.CODE === 200) {
            let existenceArray = res.data.DATA
            let errorArray = []
            this.awbList = this.queryform.awb.toUpperCase().split(',')
            this.awbList.forEach(element => {
              if (existenceArray.indexOf(element) === -1) errorArray.push(element.toUpperCase())
            })
            if (errorArray.length > 0) {
              let errorAwbStrig = ''
              errorArray.forEach((element, index) => {
                if (index > 0) errorAwbStrig += ',' + element.toUpperCase()
                else errorAwbStrig += '' + element.toUpperCase()
              })
              let infotitle = '<p>Below numbers may be incorrect or the milestone is not yet available:</p>'
              let awbNo = errorAwbStrig
              let info = infotitle + awbNo
              this.$confirm(info, 'Prompt', {
                confirmButtonText: 'lgnore and track',
                cancelButtonText: 'Back to verify',
                dangerouslyUseHTMLString: true
              }).then(() => {
                let params = {}
                for (let item in obj.data) this.$set(params, item, obj.data[item])
                this.$router.push({ path: '/cargo-tracking/tracking-result', query: params })
              }).catch(() => {
                this.errorAwbList = errorArray
                if (this.queryform.awb.indexOf(this.errorAwbList) !== false) {
                  this.$refs.awbInput.elForm.$el[0].style.background = '0 0'
                  this.$refs.awbInput.elForm.$el[0].style.position = 'relative'
                  this.$refs.awbInput.elForm.$el[0].style.zIndex = 2
                }
              })
            } else {
              let params = {}
              for (let item in obj.data) this.$set(params, item, obj.data[item])
              this.$router.push({ path: '/cargo-tracking/tracking-result', query: params })
            }
          }
        }).catch(err => {
          console.log(err)
        })
      }
    },
    reset () {
      this.queryform.awb = ''
      this.queryform.mawb = ''
      this.queryform.flightDate = ''
      this.queryform.origin = ''
      this.queryform.dest = ''
      this.queryform.shipper = ''
      this.queryform.consignee = ''
      this.queryform.outOfficeCode = ''
      this.queryform.inOfficeCode = ''
      this.errorAwbList = []
      this.errorMawbList = []
    },
    awbChange () {
      this.errorAwbList = []
    },
    mawbChange () {
      this.errorAwbList = []
    }
  }
}
</script>

<style lang="scss" scoped>
$bg1:#1890ff;
.wrap {
  .wrap-header {
    display: flex;
    padding: 15px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e5e5e5;
  }
  .wrap-content{
    padding: 5px 24px 24px;
    min-height: 72vh;
    .queryform{
      width: 55%;
      margin: 24px auto 0;
      .Advanced-title {
        width: 85%;
        text-align: flex-end;
        // border-bottom: 1px solid #e5e5e5;
        height: 30px;
        cursor: pointer;
        .span-bottom{
          // border-bottom: 3px solid #1890ff;
          border-radius: 1px;
        }
      }
      .criteria-icon{
        display: inline-block;
      }
      .query-btn {
        text-align: end;
        margin-top: 100px;
        .query-btn-box{
          width:85%;
        }
      }
      .awb-error-potision{
        width:85%;
        border-radius:5px;
        position:absolute;
        top:0;
        left:0;
        z-index:1;
        overflow: hidden;
        background-color: #fff;
        .awb-error-text{
          color: transparent;
          background-color:#fff;
        }
      }
      .mawb-error-optision{
        width:79%;
        border-radius:5px;
        position:absolute;
        padding:0 15px;
        top:0;
        left:0;
        z-index:1;
      }
    }
    .text-input-element {
      background: 0 0;
      position: relative;
      z-index: 2;
    }
    .input-error{
      background-color: red;
      color: transparent;
    }
    .input-transparent{
      color: transparent;
    }
    ::v-deep {
      .title{
        color: #000;
        font-size: 16px;
      }
      .el-input{
        width: 85%;
      }
      .el-form-item__label{
        padding: 0 35px 0 0;
      }
      .el-form-item{
        margin-bottom: 11px;
      }
      .el-input__inner{
        text-transform: uppercase !important;
      }
      .el-range-input{
        text-transform: uppercase !important;
      }
      .el-date-editor--daterange {
        width: 85%;
      }
    }
  }
}
</style>
