import request from '../../utils/request'

// 校验awb
export function validationAwb (obj) {
  return request({
    method: 'post',
    url: '/limosa-01/service/milestone/judge',
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    },
    data: obj.data,
    loading: obj.loading
  })
}

// tracking
export function trackingSubmit (obj) {
  return request({
    method: 'post',
    url: '/limosa-01/service/milestone/query',
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    },
    data: obj.data,
    loading: obj.loading
  })
}
